<template>
    <v-card elevation="0">
        <v-card-title>
            Планировщик
        </v-card-title>
        <sheduler-edit></sheduler-edit>
    </v-card>
</template>



<script>
    import ShedulerEdit from "../../components/hotel/ShedulerEdit";

    export default {
        name: 'Sheduler',
        components: {ShedulerEdit},

    }


</script>



<style>

</style>
<template>
	<v-card elevation="0">
		<v-card-title class="d-flex flex-column align-center">
			Планировщик задач
		</v-card-title>

		<v-row>
			<v-col v-for="role of visibleRoles" :key="`role-list-${role.Name}`" cols="3">
				<v-list>
					<v-list-item style="background-color: #f5f5f5">
						<v-list-item-content>
							<v-list-item-title>{{ role.Name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item-group>


					<v-list-item v-for="task of tasks[role['@Role']]" :key="`sheduler-task-id-${task['@Sheduler']}`">
						<v-list-item-content v-on:click="showChangeDialog(role, task)">
							<v-list-item-title>{{ task.Name }}</v-list-item-title>

						</v-list-item-content>
						<v-list-item-action>
							<v-btn icon>
								<v-icon color="red" v-on:click="removeTask(role, task)">mdi-close</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
					</v-list-item-group>
				</v-list>

				<v-btn
					block
					color="green"
					v-on:click="createShedulerLocal(role)"
					style="color: white"
				>
					<v-icon color="white"> mdi-plus-circle </v-icon>
				</v-btn>



			</v-col>

			<v-col cols="2" class="ma-2" v-if="hiddenRoles && hiddenRoles.length > 0">
			<v-btn
				style="width: 100%; height: 150px; border: solid 2px green; background-color: white"
				v-on:click="showRoleDialog"
			>
				<div class="d-flex flex-column align-center">
				<v-icon color="green" x-large>mdi-plus-circle-outline</v-icon>
				<v-card-title class="py-0" style="color: #7d7d7d" >Роль</v-card-title>
				</div>
			</v-btn>
			</v-col>
		</v-row>

		<v-dialog v-model="showDialog" width="50%">
			<ShedulerDialog
					:callback="currentTask ? updateTask : addTask"
					:role="currentRole"
					:close-dialog="() => {showDialog = false}"
					:task="currentTask"
					:show-dialog="showDialog"
					:action="currentTask ? 'Обновить' : 'Создать'"
					:rolesList="[currentRole]"
					:showCloseButton="true"
			></ShedulerDialog>
		</v-dialog>

		<v-dialog v-model="inAddRoleProcess" width="25%">
			<v-card>
				<v-card-title>
					Добавить роль
				</v-card-title>
				<v-select
						style="max-width: 50%"
						class="mx-auto"
						:items="hiddenRoles"
						item-text="Name"
						item-value="@Role"
						v-model="selectedRoleToAdd"
				>

				</v-select>
				<v-btn block color="green" style="color: white" v-on:click="addRole" :disabled="!selectedRoleToAdd">
					<v-icon color="white">mdi-plus</v-icon>Добавить
				</v-btn>
			</v-card>

		</v-dialog>
	</v-card>
</template>

<script>
import ShedulerDialog from '../dialogs/ShedulerDialog.vue'
import axios from 'axios';
import {getURL} from "../../settings";

export default {
	name: 'ShedulerEdit',
	components: { ShedulerDialog },

	data: () => {
		return {
			rolesCache: {},
			roles: [],
			tasks: {
				// @Role: Task[]
			},
			tasksList: [],
			tasksCache: {},
			showDialog: false,
			inAddRoleProcess: false,
			currentRole: null,
			currentTask: null,
			selectedRoleToAdd: null,
			visibleRoles: [],
			hiddenRoles: [],
		}
	},
	async mounted() {
		const rolesResponse = await axios.get(getURL('admin/role/list'),  {withCredentials: true})

		this.roles = rolesResponse.data;
		this.roles.forEach(role => {
			this.tasks[role['@Role']] = [];
			this.rolesCache[role['@Role']] = role;
		});

		await this.fetchTasks();
	},
	watch: {
		showDialog(newValue) {
			if(!newValue) {
				this.clearDialogOptions();
			}
		}
	},
	methods: {
		async fetchTasks() {
			const tasksResponse = await axios.get(getURL('admin/sheduler/list'), {withCredentials: true});
			this.tasksList = tasksResponse.data;

			let newTaskList = {};
			this.tasksList.forEach(task => {

				task.id = task['@Sheduler'];

				const taskRole = task.Role;
				if(!(taskRole in newTaskList))
					newTaskList[taskRole] = [];

				console.log('task list: ', this.tasks);

				newTaskList[taskRole].push(task);
				this.tasksCache[task['@Sheduler']] = task;

				if(taskRole in this.rolesCache){
					const roleObject = this.rolesCache[taskRole];
				if(this.visibleRoles.indexOf(roleObject) === -1)
					this.visibleRoles.push(roleObject);
				}
				
			});

			this.tasks = newTaskList;

			this.roles.forEach(role => {
				if(this.visibleRoles.indexOf(role) === -1)
					this.hiddenRoles.push(role);
			})
		},
		showChangeDialog(role, task) {
			this.showDialog = true;
			this.currentRole = role;
			this.currentTask = task;
		},
		createShedulerLocal(role) {
			this.showDialog = true;
			this.currentRole = role;
		},
		addTask(task) {
			this.showDialog = false;
			axios.post(getURL('admin/sheduler/crud'), {...task, action: 'create'}, {withCredentials: true}).then(() => {
				this.fetchTasks();
			})

		},
		updateTask(role, task) {
			this.showDialog = false;
			axios.post(getURL('admin/sheduler/crud'), {...task, action: 'update'}, {withCredentials: true}).then(() => {
				this.fetchTasks();
			})
		},
		removeTask(role, task) {
			axios.post(getURL('admin/sheduler/crud'),
					{action: 'delete', '@Sheduler': task['@Sheduler']},
					{withCredentials: true}).then(()=>{
						this.fetchTasks();
			})

		},
		clearDialogOptions() {
			this.showDialog = false;
			this.currentTask = null;
			this.currentRole = null;
		},
		showRoleDialog() {
			this.inAddRoleProcess = true;
		},
		addRole() {
			const selectedRole = this.rolesCache[this.selectedRoleToAdd];
			this.visibleRoles.push(selectedRole);
			this.hiddenRoles.splice(this.hiddenRoles.indexOf(selectedRole), 1);
			this.selectedRoleToAdd = null;
			this.inAddRoleProcess = false;
		}
	},
}
</script>

<style></style>
